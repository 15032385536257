
import { LogOutIcon, UserIcon, ShoppingCartIcon, CreditCardIcon, FileTextIcon, LayersIcon, BriefcaseIcon, KeyIcon, BellIcon, ActivityIcon, AnchorIcon, UsersIcon, AlertCircleIcon } from 'vue-feather-icons'
import { NavigationAuth } from '../../../fc/Constants/config-navigation'
import AuthManager from '~/fc/Auth/AuthManager'
import DropdownCss from '~/components/ui/DropDownCss'

export default {
  name: 'NavigationAuth',
  components: {
    UserIcon,
    LogOutIcon,
    ShoppingCartIcon,
    CreditCardIcon,
    FileTextIcon,
    LayersIcon,
    BriefcaseIcon,
    KeyIcon,
    BellIcon,
    ActivityIcon,
    AnchorIcon,
    UsersIcon,
    AlertCircleIcon,
    DropdownCss,
    NestedLinks: () => import(/* webpackChunkName: "NestedLinks" */ '../../../components/ui/NestedLinks')
  },
  data: () => ({
    userDropdown: false,
    loaded: false
  }),
  computed: {
    userLinks () { return NavigationAuth(this).userLinks },
    hasFiles () { return !!this.$store.state.list.length },
    loggedIn () { return this.loggedInAuth },
    loggedInStore () { return this.$store.state.loggedIn ? !!(this.$store.state.loggedIn) : false },
    loggedInAuth () {
      if (this.loggedInStore) {
        const authManager = new AuthManager(this)
        return !!authManager.auth?.token
      }

      return this.authManager && this.authManager.auth ? !!this.authManager.auth.token : false 
    },
    isMobile () { return this.$store.state.store.isMobile },
    conversionInProgress () { return true }, // need to place logic here
    authManager () { return new AuthManager(this) }
  },
  mounted () {
    this.$nextTick(() => {
      this.loaded = true
    })
  },
  methods: {
    openUserFiles () {
      this.$emit('userFiles')
      // eslint-disable-next-line no-unused-expressions
      this.hasFiles ? this.$refs.UserFiles.open() : false
    },
    redirectToLogIn () {
      if (!this.$route.path.includes('auth')) {
        window.localStorage.setItem('redirect', this.$route.path)
      }
      this.$emit('login')
    },
    redirectTo (link) {
      if (!this.$auth.Token) { return }
      this.$router.push(this.localePath(link))
      this.$emit('dashboard')
    },
    async logout () {
      await this.authManager.logOut()
      this.$bus.$emit('LOGOUT')
      this.$forceUpdate()
    },
    redirectToSignUp () {
      this.$emit('signup')
    }
  }
}
