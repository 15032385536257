export const NavigationLinks = (app) => {
  return {
    tools: {
      text: app.$t("navigation_link_item_tools"),
      tools: [
        {
          icon: "RepeatIcon",
          title: app.$t("file_converters"),
          items: [
            {
              icon: "VideoIcon",
              text: app.$t("ocr_converter"),
              link: "/ocr-converter",
            },
            {
              icon: "VideoIcon",
              text: app.$t("converter_video_converter"),
              link: "/video-converter",
            },
            {
              icon: "MusicIcon",
              text: app.$t("converter_audio_converter"),
              link: "/audio-converter",
            },
            {
              icon: "TabletIcon",
              text: app.$t("converter_ebook_converter"),
              link: "/ebook-converter",
            },
            {
              icon: "ImageIcon",
              text: app.$t("converter_image_converter"),
              link: "/image-converter",
            },
            {
              icon: "LayersIcon",
              text: app.$t("converter_archive_converter"),
              link: "/archive-converter",
            },
            {
              icon: "TriangleIcon",
              text: app.$t("converter_vector_converter"),
              link: "/vector-converter",
            },
            {
              icon: "FileTextIcon",
              text: app.$t("converter_document_converter"),
              link: "/document-converter",
            },
            {
              icon: "",
              text: "Video " + app.$t("to") + " MP3",
              link: "/convert/video-to-mp3",
            },
            {
              icon: "",
              text: "PDF " + app.$t("converter"),
              link: "/pdf-converter",
            },
            {
              icon: "",
              text: "Image " + app.$t("to") + " PDF",
              link: "/convert/image-to-pdf",
            },
            {
              icon: "",
              text: "Image " + app.$t("to") + " Word",
              link: "/convert/image-to-word",
            },
            {
              icon: "PercentIcon",
              text: app.$t("converter_unit_converter"),
              link: "/unit-converter",
            },
            {
              icon: "",
              text: app.$t("time_converter"),
              link: "/time/cst-to-est",
            },
          ],
        },
        {
          icon: "Minimize2Icon",
          title: app.$t("tool_title_file_compressor"),
          items: [
            {
              icon: "",
              text: app.$t("tool_video_compressor"),
              link: "/video-compressor",
            },
            {
              icon: "",
              text: app.$t("tool_image_compressor"),
              link: "/image-compressor",
            },
            {
              icon: "",
              text: "GIF " + app.$t("compressor"),
              link: "/gif-compressor",
            },
            {
              icon: "",
              text: "MP3 " + app.$t("compressor"),
              link: "/mp3-compressor",
            },
            {
              icon: "",
              text: "WAV " + app.$t("compressor"),
              link: "/wav-compressor",
            },
            {
              icon: "",
              text: app.$t("tool_compress_pdf"),
              link: "/compress-pdf",
            },
            {
              icon: "",
              text: app.$t("tool_compress_jpeg"),
              link: "/compress-jpeg",
            },
            {
              icon: "",
              text: app.$t("tool_compress_png"),
              link: "/compress-png",
            },
          ],
        },
        {
          icon: "LoaderIcon",
          title: app.$t("gif_converters"),
          items: [
            {
              icon: "",
              text: "Video " + app.$t("to") + " GIF",
              link: "/convert/video-to-gif",
            },
            {
              icon: "",
              text: "MP4 " + app.$t("to") + " GIF",
              link: "/convert/mp4-to-gif",
            },
            {
              icon: "",
              text: "WEBM " + app.$t("to") + " GIF",
              link: "/convert/webm-to-gif",
            },
            {
              icon: "",
              text: "APNG " + app.$t("to") + " GIF",
              link: "/convert/apng-to-gif",
            },
            {
              icon: "",
              text: "GIF " + app.$t("to") + " MP4",
              link: "/convert/gif-to-mp4",
            },
            {
              icon: "",
              text: "GIF " + app.$t("to") + " APNG",
              link: "/convert/gif-to-apng",
            },
            {
              icon: "",
              text: `${app.$t("Image")} ${app.$t("to")} GIF`,
              link: "/image-to-gif",
            },
            {
              icon: "",
              text: `GIF ${app.$t("Maker")}`,
              link: "/gif-maker",
            },
          ],
        },
        {
          icon: "FilmIcon",
          title: app.$t("tool_title_video_tools"),
          items: [
            { icon: "", text: app.$t("tool_crop_video"), link: "/crop-video" },
            {
              icon: "",
              text: app.$t("tool_video_trimmer"),
              link: "/video-trimmer",
            },
          ],
        },
      ],
    },
    pricing: { text: app.$t("navigation_link_item_pricing"), link: "/pricing" },
    api: {
      text: "API",
      tools: [
        {
          icon: "FileTextIcon",
          title: app.$t("documentation"),
          items: [
            {
              icon: "",
              text: "API Job Builder",
              link: "/api/job-builder",
              external: true,
            },
            {
              icon: "",
              text: "API " + app.$t("documentation"),
              link: "/api/v1/",
              external: true,
            },
          ],
        },
        {
          icon: "RepeatIcon",
          title: app.$t("conversion") + " APIs",
          items: [
            {
              icon: "",
              text: `File ${app.$t("conversion")} API`,
              link: "/api/file-conversion-api",
              external: true,
            },
            {
              icon: "",
              text: `Image ${app.$t("conversion")} API`,
              link: "/api/image-conversion-api",
              external: true,
            },
            {
              icon: "",
              text: `Audio ${app.$t("conversion")} API`,
              link: "/api/audio-conversion-api",
              external: true,
            },
            {
              icon: "",
              text: `Document ${app.$t("conversion")} API`,
              link: "/api/document-conversion-api",
              external: true,
            },
            {
              icon: "",
              text: `PDF ${app.$t("conversion")} API`,
              link: "/api/pdf-conversion-api",
              external: true,
            },
            {
              icon: "",
              text: `MP4 ${app.$t("conversion")} API`,
              link: "/api/mp4-conversion-api",
              external: true,
            },
            {
              icon: "",
              text: `Video ${app.$t("conversion")} API`,
              link: "/api/video-conversion-api",
              external: true,
            },
          ],
        },
        {
          icon: "FileIcon",
          title: app.$t("specific") + " APIs",
          items: [
            {
              icon: "",
              text: "JPG " + app.$t("to") + " PDF API",
              link: "/api/jpg-to-pdf-api",
              external: true,
            },
            {
              icon: "",
              text: "Video " + app.$t("to") + " MP3 API",
              link: "/api/video-to-mp3-api",
              external: true,
            },
            {
              icon: "",
              text: "HEIC " + app.$t("to") + " JPG API",
              link: "/api/heic-to-jpg-api",
              external: true,
            },
            {
              icon: "",
              text: "PDF " + app.$t("to") + " JPG API",
              link: "/api/pdf-to-jpg-api",
              external: true,
            },
            {
              icon: "",
              text: "Webp " + app.$t("to") + " PNG API",
              link: "/api/webp-to-png-api",
              external: true,
            },
            {
              icon: "",
              text: "PDF " + app.$t("to") + " WORD API",
              link: "/api/pdf-to-word-api",
              external: true,
            },
            {
              icon: "",
              text: "MP4 " + app.$t("to") + " MP3 API",
              link: "/api/mp4-to-mp3-api",
              external: true,
            },
            {
              icon: "",
              text: "Webp " + app.$t("to") + " JPG API",
              link: "/api/webp-to-jpg-api",
              external: true,
            },
            {
              icon: "",
              text: "WORD " + app.$t("to") + " PDF API",
              link: "/api/word-to-pdf-api",
              external: true,
            },
            {
              icon: "",
              text: "HTML " + app.$t("to") + " PDF API",
              link: "/api/html-to-pdf-api",
              external: true,
            },
            {
              icon: "",
              text: "Website " + capitalizeFirstLetter(app.$t("screenshot")) + " API",
              link: "/api/website-screenshot-api",
              external: true,
            },
          ],
        },
        {
          icon: "Minimize2Icon",
          title: app.$t("compression") + " APIs",
          items: [
            {
              icon: "",
              text: `Video ${app.$t("compression")} API`,
              link: "/api/video-compression-api",
              external: true,
            },
            {
              icon: "",
              text: `${app.$t("compress")} PDF API`,
              link: "/api/compress-pdf-api",
              external: true,
            },
            {
              icon: "",
              text: `Image ${app.$t("compression")} API`,
              link: "/api/image-compression-api",
              external: true,
            },
          ],
        },
      ],
    },
  };
};

export const NavigationAuth = (app) => {
  return {
    userLinks: {
      text: "",
      options: [
        {
          icon: "",
          text: app.$t("navigation_link_item_dashboard"),
          link: "/account/billing-plan",
        },
        { icon: "", text: app.$t("auth_logout"), link: "" },
      ],
    },
  };
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
