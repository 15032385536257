
import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons'
export default {
  name: 'DropDownCss',
  components: {
    ChevronDownIcon, ChevronUpIcon
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Menu'
    },
    theme: {
      type: String,
      required: false,
      default: 'default'
    },
    position: {
      type: String,
      required: false,
      default: 'bottom'
    },
    slottedTrigger: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    triggerOnClick (e) {
      e.preventDefault()
    }
  }
}
